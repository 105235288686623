<div class="flexbin flexbin-margin img-portfolio">

  <a href="#">
    <img src="../../../assets/images/portafolio/dreamz/dreamz-2.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/dreamz/dreamz-1.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/dreamz/dreamz-3.jpg" />
  </a>

  <a href="#">
    <img src="../../../assets/images/portafolio/hr/hr-2.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/hr/hr-1.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/hr/hr-3.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/hr/hr-4.jpg" />
  </a>

  <a href="#">
    <img src="../../../assets/images/portafolio/re/re-2.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/re/re-1.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/re/re-3.jpg" />
  </a>

  <a href="#">
    <img src="../../../assets/images/portafolio/sillows/sillows2.png" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/sillows/sillows1.png" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/sillows/sillows3.png" />
  </a>

  <a href="#">
    <img src="../../../assets/images/portafolio/h/h-2.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/h/h-1.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/h/h-3.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/h/h-4.jpg" />
  </a>

  <a href="#">
      <img src="../../../assets/images/portafolio/braza-brava/braza-brava1.jpg" />
  </a>
  <a href="#">
      <img src="../../../assets/images/portafolio/braza-brava/braza-brava2.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/braza-brava/braza-brava3.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/braza-brava/braza-brava4.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/extra/extra1.png" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/e3-offices/e3offices1.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/e3-offices/e3offices2.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/extra/extra3.png" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/geko/geko1.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/geko/geko2.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/m82/m821.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/m82/m822.jpg" />
  </a>
  <a href="#">
    <img src="../../../assets/images/portafolio/m82/m823.jpg" />
  </a>

  <a href="#">
    <img src="../../../assets/images/portafolio/extra/extra2.png" />
  </a>

  <a href="#">
    <img src="../../../assets/images/portafolio/extra/extra4.png" />
  </a>

</div>
