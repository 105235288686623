import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ScriptService } from "./services/script.service";

const SCRIPT_PATH = 'https://kit.fontawesome.com/547e5c5d33.js';
declare let gapi: any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  constructor(
      private title: Title,
      private renderer: Renderer2,
      private scriptService: ScriptService
    ) {
    title.setTitle('Monster Estudio de Diseño');
   }

  ngOnInit(): void {
    const scriptElement = this.scriptService.loadJsScript(this.renderer, SCRIPT_PATH);
    scriptElement.onload = () => {
       // Load the JavaScript client library.
       // (the init() method has been omitted for brevity)
       gapi.load('client', this.init);
     }
     scriptElement.onerror = () => {
       console.log('Could not load the Google API Script!');
     }
  }
  init(arg0: string, init: any) {
    throw new Error('Method not implemented.');
  }

}
