import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { ClientesComponent } from './inicio/clientes/clientes.component';
import { HeaderComponent } from './inicio/header/header.component';
import { CoverImageComponent } from './inicio/cover-image/cover-image.component';
import { FooterComponent } from './inicio/footer/footer.component';
import { PortafolioComponent } from './inicio/portafolio/portafolio.component';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    ClientesComponent,
    HeaderComponent,
    CoverImageComponent,
    FooterComponent,
    PortafolioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
