<div class="site-wrapper image-cover">
  <div class="site-wrapper-inner">
    <div class="container">
      <div class="inner cover">
        <div class="row">
          <div class="col-lg-6">
            <h1 class="cover-heading">Somos una Agencia para los Emprendedores!</h1>
            <p class="lead">
              ¡Hola, somos Monster Estudio de Diseño! Somos diseñadores gráficos y desarrolladores web con más de 10 años de experiencia.
              <br>¡El diseño de marca y el desarrollo de sitios web son sin duda nuestras cosas favoritas!<br>Siempre estamos buscando nuevos proyectos emocionantes.
            </p>
            <p class="lead">
              <a href="https://web.whatsapp.com/send?phone=+5218110601127&text=Hola%2C%20deseo%20informaci%C3%B3n" target="_blank" class="btn btn-lg btn-default">¡Pongámonos en Contacto!</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
