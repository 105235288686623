<footer>
  <div class="row div-footer">
    <div class="col-lg-4"></div>
    <div class="col-lg-2 logo-footer">
      <img class="svg-color-footer" src="../../../assets/images/logo-monster-estudio-black.svg" alt="">
    </div>
    <div class="col-lg-2">
      <h2>Contáctame!</h2>

        <a href="mailto:hello@monstercloudhub.com" target="_blank">hello@monstercloudhub.com</a><br>
        <a href="https://web.whatsapp.com/send?phone=+5218110601127&text=Hola%2C%20deseo%20informaci%C3%B3n" target="_blank" > +52 811 060 11 27</a><br>
        Monterrey, México

    </div>
    <div class="col-lg-4"></div>
  </div>
  <div class="text-center post-footer">
    2023 Monster Estudio de Diseño | Sitio Desarrollado en conjunto con <a href="https://monstercloudhub.com" target="_blank">monstercloudhub.com</a>
  </div>
</footer>
